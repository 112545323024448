import { Link } from "react-router-dom";

// Determine KYC status message
const GetKycStatusMessage = ({ setShowKycAlert, kycData, kycFetched }) => {
    if (kycFetched) {
        if (kycData?.data?.length > 0) {
            const kycStatus = kycData?.data[0]?.status;
            switch (kycStatus) {
                case "Approved":
                    return (
                        <div className="bg-green-100 border-l-4 flex items-center justify-between w-full border-green-500 text-green-700 p-4 mb-6 rounded-lg shadow-md">
                            <span className="font-semibold">KYC Approved</span>
                            <button
                                className="text-green-700"
                                onClick={() => setShowKycAlert(false)} // Hide the alert on click
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    );
                case "Rejected":
                    return (
                        <div className="bg-red-100 border-l-4 flex items-center justify-between w-full border-red-500 text-red-700 p-4 mb-6 rounded-lg shadow-md relative">
                            <div>
                                <span className="font-semibold">KYC Rejected</span>
                                <p className="text-sm mt-2">Please update your details and resubmit. Reason is <span className="underline font-bold">{kycData?.data[0]?.reason}</span></p>
                                <Link
                                    to={"/kyc"}
                                    className="mt-4 inline-block px-6 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition duration-300"
                                >
                                    Resubmit KYC
                                </Link>
                            </div>
                            <button
                                className="text-red-700"
                                onClick={() => setShowKycAlert(false)} // Hide the alert on click
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    );
                case "Pending":
                    return (
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 flex items-center justify-between w-full text-yellow-700 p-4 mb-6 rounded-lg shadow-md relative">
                            <div>
                                <span className="font-semibold">KYC Pending</span>
                                <p className="text-sm mt-2">Your KYC is still under review.</p>
                            </div>
                            <button
                                className=" text-yellow-700"
                                onClick={() => setShowKycAlert(false)} // Hide the alert on click
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    );
                default:
                    return
            }
        }
        return (
            <div className="bg-gradient-to-r from-teal-50 to-teal-100 border-l-4 border-teal-500 text-teal-700 p-6 mb-6 rounded-lg shadow-lg relative flex items-center">
                <div className="flex-grow">
                    <h3 className="font-bold text-lg">Complete Your KYC</h3>
                    <p className="text-sm mt-2">
                        Completing your KYC helps us ensure security and provides you with full access to all features. It only takes a few minutes!
                    </p>
                    <Link
                        to={"/kyc"}
                        className="mt-4 block w-fit px-6 py-2 bg-teal-500 text-white font-semibold rounded-lg shadow-md hover:bg-teal-600 transition duration-300"
                    >
                        Complete KYC Now
                    </Link>
                </div>
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition duration-300"
                    onClick={() => setShowKycAlert(false)} // Hide the alert on click
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        );

    }
    return null; // If KYC is not fetched, return null
};

export default GetKycStatusMessage