import React, { useState } from "react";

const KYCForm = ({ kycFormData, setKycFormData, handleKycSubmit }) => {
    // State to manage error messages and loading state
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Handle file change for image uploads
    const handleFileChange = (e, field) => {
        const file = e?.target?.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setKycFormData((prevData) => ({
                    ...prevData,
                    [field]: {
                        file: file, // Store the file object for upload
                        base64: reader.result, // Save the base64 string for preview
                    },
                }));
            };
            reader.readAsDataURL(file); // Convert the file to base64 for preview
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await handleKycSubmit();
            setError(""); // Clear error on successful validation
        } catch (validationError) {
            setError(validationError.message || "An error occurred. Please try again.");
        } finally {
            setIsSubmitting(false); // Reset the loading state after submission attempt
        }
    };

    return (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg mt-6 max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold text-teal-400 text-center mb-6">KYC Form</h2>

            {/* Error message display */}
            {error && <div className="text-red-500 text-center mb-4">{error}</div>}

            <form onSubmit={handleSubmit}>
                {/* PAN Card Front Image */}
                <div className="mb-6">
                    <label className="text-gray-300 block mb-2" htmlFor="pancard_front_image">
                        Upload PAN Card Front Image
                    </label>
                    <input
                        type="file"
                        id="pancard_front_image"
                        onChange={(e) => handleFileChange(e, "pancard_front_image")}
                        className="border border-teal-500 p-2 rounded-lg w-full bg-gray-700 text-white"
                    />
                </div>

                {/* PAN Card Back Image */}
                <div className="mb-6">
                    <label className="text-gray-300 block mb-2" htmlFor="pancard_back_image">
                        Upload PAN Card Back Image
                    </label>
                    <input
                        type="file"
                        id="pancard_back_image"
                        onChange={(e) => handleFileChange(e, "pancard_back_image")}
                        className="border border-teal-500 p-2 rounded-lg w-full bg-gray-700 text-white"
                    />
                </div>

                {/* License Front Image */}
                <div className="mb-6">
                    <label className="text-gray-300 block mb-2" htmlFor="licence_front_image">
                        Upload License Front Image
                    </label>
                    <input
                        type="file"
                        id="licence_front_image"
                        onChange={(e) => handleFileChange(e, "licence_front_image")}
                        className="border border-teal-500 p-2 rounded-lg w-full bg-gray-700 text-white"
                    />
                </div>

                {/* License Back Image */}
                <div className="mb-6">
                    <label className="text-gray-300 block mb-2" htmlFor="licence_back_image">
                        Upload License Back Image
                    </label>
                    <input
                        type="file"
                        id="licence_back_image"
                        onChange={(e) => handleFileChange(e, "licence_back_image")}
                        className="border border-teal-500 p-2 rounded-lg w-full bg-gray-700 text-white"
                    />
                </div>

                {/* File Uploads Preview */}
                <div className="mb-6">
                    <p className="text-gray-300">Uploaded Files Preview:</p>
                    <div className="flex flex-wrap gap-4 mt-2">
                        {kycFormData.pancard_front_image?.base64 && (
                            <img
                                src={kycFormData.pancard_front_image.base64}
                                alt="PAN Card Front"
                                className="w-20 h-20 object-cover rounded-lg border-2 border-teal-500"
                            />
                        )}
                        {kycFormData.pancard_back_image?.base64 && (
                            <img
                                src={kycFormData.pancard_back_image.base64}
                                alt="PAN Card Back"
                                className="w-20 h-20 object-cover rounded-lg border-2 border-teal-500"
                            />
                        )}
                        {kycFormData.licence_front_image?.base64 && (
                            <img
                                src={kycFormData.licence_front_image.base64}
                                alt="License Front"
                                className="w-20 h-20 object-cover rounded-lg border-2 border-teal-500"
                            />
                        )}
                        {kycFormData.licence_back_image?.base64 && (
                            <img
                                src={kycFormData.licence_back_image.base64}
                                alt="License Back"
                                className="w-20 h-20 object-cover rounded-lg border-2 border-teal-500"
                            />
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                    <button
                        type="submit"
                        className={`bg-teal-500 text-white px-6 py-3 rounded-lg hover:bg-teal-600 transition duration-200 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Submitting..." : "Submit KYC"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default KYCForm;
