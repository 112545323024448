import React, { useRef, useState, useEffect } from "react";
import Select from "react-select"; // Import React-Select
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetQuizzesByCategoryQuery, useLazyGetQuizzesQuery } from "../api/apiSlice";
import { setQuizzes } from "../redux/dataSlice";

const CategoryList = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.data.categories);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const [getQuizzesByCategory, { data: quizData, isSuccess }] = useLazyGetQuizzesByCategoryQuery();
    const [getAllQuiz, result] = useLazyGetQuizzesQuery();

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: -200,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: 200,
                behavior: "smooth",
            });
        }
    };

    const handleScroll = () => {
        const container = scrollRef.current;
        if (container) {
            setIsAtStart(container.scrollLeft === 0);
            setIsAtEnd(container.scrollLeft + container.clientWidth >= container.scrollWidth);
        }
    };

    useEffect(() => {
        if (selectedCategory) {
            getQuizzesByCategory(selectedCategory);
        } else {
            getAllQuiz({});
        }
    }, [selectedCategory, getAllQuiz, getQuizzesByCategory]);

    useEffect(() => {
        if (isSuccess && selectedCategory) {
            dispatch(setQuizzes(quizData.data));
        }
        if (result.isSuccess && !selectedCategory) {
            dispatch(setQuizzes(result.data.data));
        }
    }, [quizData, isSuccess, result, dispatch, selectedCategory]);

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption?.value || null);
    };

    // Convert categories to React-Select options
    const categoryOptions = [
        { value: null, label: "All" },
        ...categories.map((category) => ({
            value: category.id,
            label: category.name,
        })),
    ];

    return (
        <div className="relative py-6">
            <div className="block md:hidden mb-4">
                <Select
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    defaultValue={categoryOptions[0]}
                    placeholder="Select a category..."
                    className="text-white"
                    isSearchable // Enable searching
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: '#2d3748', // Dark background for the dropdown
                            borderColor: '#4fd1c5', // Teal border color
                            borderRadius: '0.375rem', // Rounded corners
                            padding: '0.5rem', // Padding inside the control
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
                            transition: 'border-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition for focus
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: '#81e6d9', // Light teal color for placeholder text
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#2d3748', // Dark background for the dropdown menu
                            borderRadius: '0.375rem',
                            borderColor: '#4fd1c5', // Teal border around the dropdown
                            boxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)', // Enhanced shadow for dropdown
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? '#4fd1c5' : 'transparent', // Highlight selected option
                            color: state.isSelected ? '#2d3748' : '#cbd5e0', // Text color based on selection
                            padding: '10px', // Increase padding for better clickability
                            transition: 'background-color 0.3s ease', // Smooth background color transition
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: '#81e6d9', // Text color for selected value
                        }),
                        input: (provided) => ({
                            ...provided,
                            color: '#ffffff', // Set input text color to white
                        }),
                    }}
                />
            </div>



            {/* Scrollable List for Larger Screens */}
            <div className="hidden md:block">
                {/* Scroll Left Button */}
                <button
                    onClick={scrollLeft}
                    disabled={isAtStart}
                    className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-teal-500 text-white rounded-full p-2 shadow-lg hover:bg-teal-600 transition sm:p-3 ${isAtStart ? "hidden" : "block"}`}
                    aria-label="Scroll left"
                >
                    &#8592;
                </button>

                {/* Scrollable Category List */}
                <div
                    ref={scrollRef}
                    className="flex overflow-hidden no-scrollbar md:space-x-4 md:px-4 md:py-4 space-x-2 scroll-smooth"
                    onScroll={handleScroll}
                >
                    {/* "All" Button */}
                    <button
                        onClick={() => setSelectedCategory(null)}
                        className={`whitespace-nowrap bg-transparent md:text-sm text-xs border border-white rounded-full p-2 sm:px-6 hover:bg-white hover:text-teal-600 transition duration-300 ${selectedCategory === null ? "bg-white text-teal-600" : "text-white"
                            }`}
                    >
                        All
                    </button>

                    {/* Render Active Categories */}
                    {categories.map((category) => (
                        <button
                            key={category.id}
                            onClick={() => setSelectedCategory(category.id)}
                            className={`whitespace-nowrap bg-transparent md:text-sm text-xs border border-white rounded-full p-2 sm:px-6 hover:bg-white hover:text-teal-600 transition duration-300 ${selectedCategory === category.id ? "bg-white text-teal-600" : "text-white"
                                }`}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>

                {/* Scroll Right Button */}
                <button
                    onClick={scrollRight}
                    disabled={isAtEnd}
                    className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-teal-500 text-white rounded-full p-2 shadow-lg hover:bg-teal-600 transition sm:p-3 ${isAtEnd ? "hidden" : "block"}`}
                    aria-label="Scroll right"
                >
                    &#8594;
                </button>
            </div>
        </div>
    );
};

export default CategoryList;
