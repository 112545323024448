// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

// This component will check for authentication
const ProtectedRoute = ({ element }) => {
    const token = localStorage.getItem("token"); // Check if the token is in localStorage

    if (!token) {
        // If no token is found, redirect to the login page
        return <Navigate to="/" />;
    }

    return element; // Return the protected route if the user is authenticated
};

export default ProtectedRoute;
