import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";

const QuizModal = ({ quiz, isOpen, closeModal }) => {
    const token = localStorage.getItem("token");
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const navigate = useNavigate();

    // Early return if the modal is not open
    if (!isOpen) return null;

    // Handlers
    const handlePlayAsGuest = () => {
        closeModal(); // Close the modal before navigating
        navigate(`/quiz/${quiz?.quiz?.id}`);
    };

    const handleJoinNow = () => {
        setIsProfileOpen(true); // Open profile modal
    };

    const closeProfileModal = () => {
        setIsProfileOpen(false); // Close profile modal
    };

    return (
        <>
            {/* Profile Modal */}
            {isProfileOpen && <Profile closeModel={closeProfileModal} />}

            {/* Main Quiz Modal */}
            <div
                className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${isProfileOpen ? "hidden" : "block"}`}
                role="dialog"
                aria-modal="true"
                aria-labelledby="quiz-modal-title"
                aria-hidden={!isOpen}
            >
                <div className="rounded-2xl p-6 sm:p-8 md:p-10 text-center w-[90%] max-w-md md:max-w-lg lg:max-w-xl relative bg-gradient-to-br from-gray-800 to-gray-900 shadow-2xl text-white">
                    {/* Close Button */}
                    <button
                        onClick={closeModal}
                        className="absolute top-4 right-4 text-gray-300 hover:text-white text-2xl sm:text-3xl font-bold"
                        aria-label="Close quiz modal"
                    >
                        &times;
                    </button>

                    {/* Modal Content */}
                    <div className="text-center mb-6">
                        {/* Quiz Category Image */}
                        {quiz?.category?.image && (
                            <div className="flex justify-center mb-6 animate__animated animate__fadeIn">
                                <img
                                    src={quiz.category.image}
                                    alt={quiz.category.name || "Quiz Category"}
                                    className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-4 border-teal-500 shadow-lg"
                                />
                            </div>
                        )}

                        {/* Quiz Title */}
                        <h2
                            id="quiz-modal-title"
                            className="text-2xl sm:text-3xl font-extrabold mb-4 text-teal-400"
                        >
                            {quiz?.category?.name || "Quiz Title"}
                        </h2>

                        {/* Play & Win and Entry Fee */}
                        <div className="flex flex-col md:flex-row justify-between items-center text-base sm:text-lg mb-6 space-y-4 md:space-y-0">
                            <div className="text-center">
                                <span className="font-bold text-gray-300">Play & Win:</span>
                                <div className="flex items-center justify-center text-yellow-400 mt-1">
                                    <span className="text-xl sm:text-2xl">
                                        {quiz?.quiz?.total_price || 0}{" "}
                                        <span className="text-sm sm:text-lg">Coins</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Instructions */}
                        <div className="text-gray-300 text-left text-sm sm:text-base md:text-lg mb-6 space-y-2">
                            <p className="flex items-center">
                                <span className="mr-2">⏱️</span>
                                <span>You have <span className="font-semibold text-teal-400">10 seconds</span> for each question.</span>
                            </p>
                            <p className="flex items-center">
                                <span className="mr-2">💡</span>
                                <span>
                                    Each correct answer earns <span className="font-semibold text-green-400">+100 points</span>,
                                    while incorrect answers result in a <span className="font-semibold text-red-400">-10 point deduction</span>.
                                </span>
                            </p>
                        </div>

                        {/* Action Buttons */}
                        <div className="flex flex-col sm:flex-row justify-between gap-4">
                            {!token ? (
                                <button
                                    onClick={handleJoinNow}
                                    className="w-full bg-teal-500 text-white py-3 rounded-lg shadow-lg hover:bg-teal-600 transform transition-all duration-200"
                                >
                                    Join Now
                                </button>
                            ) : (
                                <button
                                    onClick={handlePlayAsGuest}
                                    className="w-full bg-gray-300 text-black py-3 rounded-lg shadow-lg hover:bg-gray-400 transform transition-all duration-200"
                                >
                                    Play
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuizModal;
