import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/userSlice";
import { useLazyGetKYCDetailsQuery, useLazyGetMeQuery, useWithdrawRequestMutation } from "../api/apiSlice";
import useCoinConversion from "../hooks/useCoinConversion";
import PaymentMethodSelector from "../components/PaymentMethodSelector";
import CoinInputForm from "../components/CoinInputForm";
import KYCForm from "../components/KYCForm";
import { useSubmitKYCMutation, useUploadImageMutation } from "../api/apiSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function WithdrawalPageNew() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [triggerFetchMe, { data: meData, isSuccess }] = useLazyGetMeQuery();
    const [triggerGetKycDetails, { data: kycData, isSuccess: kycFetched }] = useLazyGetKYCDetailsQuery();
    const [withdrawRequest, { isSuccess: withdrawSuccess, data: withdrawData, isLoading: withdrawLoading }] = useWithdrawRequestMutation();

    const {
        coinAmount,
        setCoinAmount,
        rupeeAmount,
        message,
        messageType,
        handleConversion,
        isConversionSuccessful,
        isLoading,
    } = useCoinConversion();

    const [kycFormData, setKycFormData] = useState({
        pancard_front_image: { file: null, base64: "" },
        pancard_back_image: { file: null, base64: "" },
        licence_front_image: { file: null, base64: "" },
        licence_back_image: { file: null, base64: "" },
    });
    const [isKycFormVisible, setIsKycFormVisible] = useState(false);
    const [isKycCompleted, setIsKycCompleted] = useState(false);
    const [paymentType, setPaymentType] = useState(null);
    const [isPaymentMethodSelected, setIsPaymentMethodSelected] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState({
        coin: 0,
    });

    const [uploadImage] = useUploadImageMutation();
    const [submitKYC, { isSuccess: isSubmit, data: submitData }] = useSubmitKYCMutation();
    useEffect(() => {
        if (isSubmit) {
            triggerGetKycDetails()
            Swal.fire({
                title: 'Success!',
                text: submitData?.data?.message || 'KYC details have been updated or fetched successfully.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        }
    }, [isSubmit, submitData, triggerGetKycDetails]);
    useEffect(() => {
        triggerFetchMe();
    }, [triggerFetchMe]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setUser(meData?.data));
        }
    }, [isSuccess, meData, dispatch]);

    useEffect(() => {
        if (coinAmount) {
            setWithdrawAmount(coinAmount)
        }
    }, [coinAmount]);

    useEffect(() => {
        if (kycFetched && kycData?.data) {
            const { status } = kycData?.data[0];
            if (status === "Approved") {
                setIsKycCompleted(true);
            } else if (status === "Rejected") {
                setIsKycFormVisible(true);
            } else if (status === "Pending") {
                setIsKycFormVisible(false);
            }
        }
        else if (kycData?.message === "No KYC details found for the user.") {
            setIsKycFormVisible(true)
            setIsKycCompleted(false)
        }
    }, [kycFetched, kycData]);

    const handlePaymentTypeSelect = (paymentType) => {
        setIsPaymentMethodSelected(true);
        setPaymentType(paymentType)
        triggerGetKycDetails();
    };

    const handleImageUpload = async (file, imageType) => {
        const formData = new FormData();
        formData.append("image", file);
        try {
            const { data } = await uploadImage(formData);
            if (data?.fileUrl) {
                if (paymentType === "QRCode") {
                    setPaymentDetails(data.fileUrl)
                }
                setKycFormData((prev) => ({
                    ...prev,
                    [imageType]: {
                        ...prev[imageType],
                        base64: data.fileUrl,
                    },
                }));
                return { url: data.fileUrl, imageType };
            }
            throw new Error("File URL is missing in the response");
        } catch (error) {
            console.error("Error uploading image:", error);
            throw new Error(`Failed to upload ${imageType}`);
        }
    };
    const handleKycSubmit = async () => {
        try {
            const imageFields = [
                { field: "pancard_front_image", file: kycFormData.pancard_front_image.file },
                { field: "pancard_back_image", file: kycFormData.pancard_back_image.file },
                { field: "licence_front_image", file: kycFormData.licence_front_image.file },
                { field: "licence_back_image", file: kycFormData.licence_back_image.file },
            ];


            const uploadPromises = imageFields.map(async ({ field, file }) => {
                if (file) {
                    const { url, imageType } = await handleImageUpload(file, field);
                    return { [imageType]: url };
                }
                return null;
            });


            const uploadedImages = await Promise.all(uploadPromises);


            const allUrls = uploadedImages.reduce((acc, curr) => {
                if (curr) {
                    acc = { ...acc, ...curr };
                }
                return acc;
            }, {});

            if (
                !allUrls.pancard_front_image ||
                !allUrls.pancard_back_image ||
                !allUrls.licence_front_image ||
                !allUrls.licence_back_image
            ) {
                throw new Error("Some image uploads failed. Please try again.");
            }

            const kycPayload = {
                pancard_front_image: allUrls.pancard_front_image,
                pancard_back_image: allUrls.pancard_back_image,
                licence_front_image: allUrls.licence_front_image,
                licence_back_image: allUrls.licence_back_image,
                kycId: kycData?.data?.[0]?.id || undefined,
            };

            await submitKYC(kycPayload).unwrap();
            setIsKycCompleted(true);
            navigate("/")
        } catch (error) {
            console.error("Error submitting KYC:", error);
        }
    };


    // Validation functions
    const validateUpiId = (upiId) => {
        const upiRegex = /^[\w.-]+@[a-zA-Z]+$/;
        return upiRegex.test(upiId);
    };

    const validateMobileNumber = (mobileNumber) => {
        return mobileNumber.length >= 10 && mobileNumber.length <= 12 && /^\d+$/.test(mobileNumber);
    };

    const handleWithdrawRequest = async () => {
        if (!withdrawAmount || !paymentType || !paymentDetails) {
            Swal.fire({
                title: 'Error',
                text: 'Please fill all the fields before submitting the withdrawal request.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
            return;
        }

        // Validate UPI and Mobile Number
        if (paymentType === "UPI" && !validateUpiId(paymentDetails)) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a valid UPI ID.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
            return;
        }

        if (paymentType === "MobileNumber" && !validateMobileNumber(paymentDetails)) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a valid 12-digit mobile number.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
            return;
        }

        const payload = {
            coins: Number(withdrawAmount || 0),
            payment_type: paymentType,
            payment_details: paymentDetails,
        };

        try {
            await withdrawRequest(payload).unwrap();
            Swal.fire({
                title: 'Success!',
                text: withdrawData?.message || 'Your withdrawal request has been successfully submitted.',
                icon: 'success',
                confirmButtonText: 'OK',
            });
            triggerFetchMe();
            navigate("/withdraw-history")
        } catch (error) {
            console.error("Error submitting withdrawal request:", error);
            Swal.fire({
                title: 'Error',
                text: error?.data?.message || 'Something went wrong.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    };
    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 text-white p-6 flex flex-col items-center">
            <div className="max-w-lg bg-gray-700 rounded-lg p-8 shadow-lg">
                <h1 className="text-2xl font-bold text-center text-teal-400 mb-6">
                    {!isConversionSuccessful ? "Convert Coins to Rupees" : "Select Payment Method"}
                </h1>

                {!isConversionSuccessful && (
                    <p className="text-sm text-gray-300 text-center mb-6">
                        Enter the number of coins to see their equivalent value in rupees. Ensure you have enough coins in your account.
                    </p>
                )}

                {rupeeAmount !== null && (
                    <div className="mt-6 text-center px-4 sm:px-6 lg:px-8">
                        {/* Card-like container with smooth animation */}
                        <div className="mt-3 bg-gray-800 p-6 rounded-lg shadow-lg transform transition-all duration-300">
                            <p className="text-4xl sm:text-5xl font-extrabold text-teal-400 animate__animated animate__fadeIn">
                                ₹{rupeeAmount.toLocaleString()}
                            </p>
                            <p className="mt-2 text-sm sm:text-base text-gray-400">Equivalent value in rupees</p>
                        </div>

                    </div>
                )}


                {!isConversionSuccessful && (
                    <CoinInputForm
                        coinAmount={coinAmount}
                        setCoinAmount={setCoinAmount}
                        message={message}
                        messageType={messageType}
                        handleConversion={handleConversion}
                        isLoading={isLoading}
                    />
                )}

                {isConversionSuccessful && !isKycCompleted && !isPaymentMethodSelected && (
                    <div className="mt-8">
                        <PaymentMethodSelector onPaymentMethodSelect={handlePaymentTypeSelect} activeMethod={paymentType} />
                    </div>
                )}

                {kycData?.data && kycData?.data[0]?.status === "Pending" && (
                    <div className="bg-yellow-100 text-yellow-800 mt-4 border-l-4 border-yellow-500 p-4 rounded-md shadow-sm text-center mb-6">
                        <p className="text-sm">
                            <span className="inline-block mr-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 inline text-yellow-500"
                                    fill="none"
                                    viewBox="0 0 30 30"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 8a16.88 16.88 0 01-.883 6.757m-1.513 2.958A16.978 16.978 0 0112 21a16.978 16.978 0 01-6.604-3.285M3 3l18 18" />
                                </svg>
                            </span>
                            Your KYC is under review. Please wait for confirmation.
                        </p>
                    </div>
                )}

                {isKycFormVisible && !isKycCompleted && isConversionSuccessful && isPaymentMethodSelected && (
                    <div className="mt-6">
                        {kycData?.data && kycData?.data[0]?.status === "Rejected" && (
                            <>
                                <div className="bg-red-100 text-red-800 border-l-4 border-red-500 p-4 rounded-md shadow-sm text-center mb-6">
                                    <p className="text-sm">
                                        <span className="inline-block mr-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-w-10 w-5 inline text-red-500"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 11-12.728 12.728A9 9 0 0118.364 5.636z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01" />
                                            </svg>
                                        </span>
                                        Your KYC was rejected. Please update your details and resubmit.
                                    </p>
                                    <p className="text-xs text-gray-700 mt-2">
                                        Ensure your uploaded documents are clear and meet the required standards.
                                    </p>
                                    {kycData?.data[0]?.reason && (
                                        <p className="text-xs text-red-700 mt-2">
                                            <strong>Rejection Reason:</strong> {kycData?.data[0]?.reason}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                        <KYCForm
                            kycFormData={kycFormData}
                            setKycFormData={setKycFormData}
                            handleKycSubmit={handleKycSubmit}
                        />
                    </div>
                )}

                {isKycCompleted && kycData?.data && kycData?.data[0]?.status === "Approved" && !withdrawSuccess && (
                    <div className="mt-8">
                        <h2 className="text-xl font-bold text-teal-400">Withdraw Coins</h2>

                        <div className="mt-8">
                            <PaymentMethodSelector onPaymentMethodSelect={handlePaymentTypeSelect} activeMethod={paymentType} />
                        </div>

                        <div className="mt-4">
                            <label htmlFor="paymentDetails" className="block text-sm text-gray-300">
                                Payment Details:
                            </label>
                            {
                                paymentType === "QRCode" ? <input
                                    type="file"
                                    id="qrCodeImage"
                                    onChange={(e) => handleImageUpload(e.target.files[0], "qrCodeImage")}
                                    className="w-full p-2 mt-2 rounded-md bg-gray-800 border border-gray-500 text-white focus:outline-none focus:border-teal-500 transition"
                                    accept="image/*"
                                /> :
                                    <input
                                        type="text"
                                        id="paymentDetails"
                                        value={paymentDetails}
                                        onChange={(e) => setPaymentDetails(e.target.value)}
                                        className="w-full p-2 mt-2 rounded-md bg-gray-800 border border-gray-500 text-white focus:outline-none focus:border-teal-500 transition"
                                        placeholder="Enter your payment details"
                                    />
                            }
                        </div>

                        <div className="mt-4">
                            <label htmlFor="withdrawAmount" className="block text-sm text-gray-300">
                                Enter Coins to Withdraw:
                            </label>
                            <input
                                type="number"
                                id="withdrawAmount"
                                value={withdrawAmount}
                                onChange={(e) => setWithdrawAmount(Number(e.target.value))}
                                className="w-full p-2 mt-2 rounded-md bg-gray-800 border border-gray-500 text-white focus:outline-none focus:border-teal-500 transition"
                                placeholder="Amount in coins"
                            />
                        </div>

                        <div className="mt-6 text-center">
                            <button
                                onClick={handleWithdrawRequest}
                                disabled={withdrawLoading}
                                className="md:px-4 md:py-2 p-1 bg-teal-500 text-white rounded-md"
                            >
                                {withdrawLoading ? "Submitting..." : "Submit Withdrawal Request"}
                            </button>
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
}
