import React, { useEffect, useState } from "react";
import CategoryList from "../components/CategoryList";
import QuizList from "../components/QuizList";
import { useLazyGetCategoriesQuery, useLazyGetQuizzesQuery, useLazyGetKYCDetailsQuery } from "../api/apiSlice";
import { useDispatch } from "react-redux";
import { setCategories, setQuizzes } from "../redux/dataSlice";
import LoadingSpinner from "../components/LoadingSpinner";
import GetKycStatusMessage from "../components/GetKycStatusMessage";

const Home = () => {
    const dispatch = useDispatch();
    const [triggerGetKycDetails, { data: kycData, isSuccess: kycFetched }] = useLazyGetKYCDetailsQuery();
    const [getCategories, result] = useLazyGetCategoriesQuery();
    const [getQuizzes, quizResult] = useLazyGetQuizzesQuery();
    const { isSuccess, isFetching, data } = result;
    const token = localStorage.getItem("token");

    const [showKycAlert, setShowKycAlert] = useState(() => {
        const dismissed = localStorage.getItem("kycAlertDismissed");
        return dismissed !== "true";
    });

    useEffect(() => {
        getCategories({});
        getQuizzes({});
        if (token && !localStorage.getItem("kycAlertDismissed")) {
            triggerGetKycDetails({});
        }
    }, [getCategories, getQuizzes, token, triggerGetKycDetails]);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            dispatch(setCategories(data.data));
        }
        if (quizResult.isSuccess && !quizResult.isFetching) {
            dispatch(setQuizzes(quizResult.data.data));
        }
    }, [isSuccess, isFetching, data, quizResult, dispatch]);


    const handleDismissKycAlert = () => {
        if (kycData?.data && kycData?.data[0]?.status === "Approved") {
            localStorage.setItem("kycAlertDismissed", "true");
            setShowKycAlert(false);
        }
        setShowKycAlert(false);

    };

    return (
        <div className="container mx-auto px-6 py-8">
            {isFetching ? (
                <div className="flex justify-center items-center h-screen">
                    <LoadingSpinner />
                </div>
            ) : (
                <>
                    <div className="mb-6">
                        {showKycAlert && (
                            <GetKycStatusMessage
                                setShowKycAlert={handleDismissKycAlert}
                                kycFetched={kycFetched}
                                kycData={kycData}
                            />
                        )}
                    </div>
                    <CategoryList />
                    <QuizList />
                </>
            )}
        </div>
    );
};

export default Home;
