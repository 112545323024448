import React, { useState } from "react";
import QuizCard from "./QuizCard";
import QuizModal from "./QuizModal";
import { useSelector } from "react-redux";

const QuizList = () => {
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const quizzes = useSelector((state) => state.data.quizzes);

    const openModal = (quiz, category) => {
        setSelectedQuiz({ quiz, category });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl sm:text-3xl font-extrabold text-center text-white mb-6">
                Available Quizzes
            </h2>

            {quizzes?.length === 0 ? (
                <div className="text-center text-white text-lg mt-8">No quizzes available at the moment.</div>
            ) : (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                    {quizzes && quizzes.map((quiz) => (
                        <QuizCard key={quiz.id} quiz={quiz} openModal={openModal} />
                    ))}
                </div>
            )}

            <QuizModal quiz={selectedQuiz} isOpen={isModalOpen} closeModal={closeModal} />
        </div>
    );
};

export default QuizList;
