import React, { useEffect, useState } from "react";
import KYCForm from "../components/KYCForm";
import { useNavigate } from "react-router-dom";
import {
    useLazyGetKYCDetailsQuery,
    useSubmitKYCMutation,
    useUploadImageMutation,
} from "../api/apiSlice";
import Swal from "sweetalert2";

export default function KycPage() {
    const navigate = useNavigate();
    const [uploadImage] = useUploadImageMutation();
    const [submitKYC, { isSuccess: isSubmit, data: submitData }] =
        useSubmitKYCMutation();
    const [triggerGetKycDetails, { data: kycData, isSuccess: kycFetched }] =
        useLazyGetKYCDetailsQuery();

    const [kycFormData, setKycFormData] = useState({
        pancard_front_image: { file: null, base64: "" },
        pancard_back_image: { file: null, base64: "" },
        licence_front_image: { file: null, base64: "" },
        licence_back_image: { file: null, base64: "" },
    });

    useEffect(() => {
        triggerGetKycDetails();
    }, [triggerGetKycDetails]);

    useEffect(() => {
        if (isSubmit) {
            Swal.fire({
                title: "Success!",
                text:
                    submitData?.data?.message ||
                    "KYC details have been updated successfully.",
                icon: "success",
                confirmButtonText: "OK",
            });
            navigate("/"); // Redirect after success
        }
    }, [isSubmit, submitData, navigate]);

    const handleImageUpload = async (file, imageType) => {
        const formData = new FormData();
        formData.append("image", file);
        try {
            const { data } = await uploadImage(formData);
            if (data?.fileUrl) {
                setKycFormData((prev) => ({
                    ...prev,
                    [imageType]: {
                        ...prev[imageType],
                        base64: data.fileUrl,
                    },
                }));
                return { url: data.fileUrl, imageType };
            }
            throw new Error("File URL is missing in the response");
        } catch (error) {
            console.error("Error uploading image:", error);
            throw new Error(`Failed to upload ${imageType}`);
        }
    };

    const handleKycSubmit = async () => {
        try {
            const imageFields = [
                {
                    field: "pancard_front_image",
                    file: kycFormData.pancard_front_image.file,
                },
                {
                    field: "pancard_back_image",
                    file: kycFormData.pancard_back_image.file,
                },
                {
                    field: "licence_front_image",
                    file: kycFormData.licence_front_image.file,
                },
                {
                    field: "licence_back_image",
                    file: kycFormData.licence_back_image.file,
                },
            ];

            const uploadPromises = imageFields.map(async ({ field, file }) => {
                if (file) {
                    const { url, imageType } = await handleImageUpload(file, field);
                    return { [imageType]: url };
                }
                return null;
            });

            const uploadedImages = await Promise.all(uploadPromises);

            const allUrls = uploadedImages.reduce((acc, curr) => {
                if (curr) {
                    acc = { ...acc, ...curr };
                }
                return acc;
            }, {});

            if (
                !allUrls.pancard_front_image ||
                !allUrls.pancard_back_image ||
                !allUrls.licence_front_image ||
                !allUrls.licence_back_image
            ) {
                throw new Error("Some image uploads failed. Please try again.");
            }

            const kycPayload = {
                pancard_front_image: allUrls.pancard_front_image,
                pancard_back_image: allUrls.pancard_back_image,
                licence_front_image: allUrls.licence_front_image,
                licence_back_image: allUrls.licence_back_image,
                kycId: kycData?.data?.[0]?.id || undefined,

            };

            await submitKYC(kycPayload).unwrap();
        } catch (error) {
            console.error("Error submitting KYC:", error);
        }
    };

    const renderKycStatus = () => {
        if (kycFetched) {
            if (kycData?.data?.length > 0) {
                const kycStatus = kycData?.data[0]?.status;
                switch (kycStatus) {
                    case "Approved":
                        return (
                            <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6 rounded-lg shadow-md">
                                <span className="font-semibold">KYC Approved</span>
                                <p className="text-sm mt-2">
                                    Your KYC has been successfully approved!
                                </p>
                            </div>
                        );
                    case "Rejected":
                        return (
                            <>
                                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg shadow-md">
                                    <span className="font-semibold">KYC Rejected</span>
                                    <p className="text-sm mt-2">
                                        Your KYC was rejected. Please resubmit with correct details.
                                    </p>
                                </div>
                                <KYCForm
                                    kycFormData={kycFormData}
                                    setKycFormData={setKycFormData}
                                    handleKycSubmit={handleKycSubmit}
                                />
                            </>
                        );
                    case "Pending":
                        return (
                            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6 rounded-lg shadow-md">
                                <span className="font-semibold">KYC Pending</span>
                                <p className="text-sm mt-2">
                                    Your KYC is under review. Please wait for the approval.
                                </p>
                            </div>
                        );
                    default:
                        return (
                            <div className="bg-gray-100 border-l-4 border-gray-500 text-gray-700 p-4 mb-6 rounded-lg shadow-md">
                                <span className="font-semibold">KYC Status Unknown</span>
                            </div>
                        );
                }
            } else {
                return (
                    <KYCForm
                        kycFormData={kycFormData}
                        setKycFormData={setKycFormData}
                        handleKycSubmit={handleKycSubmit}
                    />
                );
            }
        }
        return null;
    };

    return <div className="container mx-auto px-6 py-8">{renderKycStatus()}</div>;
}
