// NotFound.js
import React from "react";
import { Link } from "react-router-dom"; // For adding a link back to home
import { IoMdSad } from "react-icons/io"; // Add a sad face icon for visual appeal

const NotFound = () => {
    return (
        <div className="flex justify-center items-center min-h-screen text-white">
            <div className="text-center space-y-6 px-6 py-8 md:px-12">
                <IoMdSad className="text-6xl md:text-8xl mx-auto" />
                <h1 className="text-5xl font-extrabold tracking-wide">404</h1>
                <p className="text-xl md:text-2xl font-medium opacity-75">
                    Oops! The page you're looking for doesn't exist.
                </p>
                <Link
                    to="/"
                    className="inline-block mt-6 text-lg bg-teal-700 hover:bg-teal-800 px-6 py-3 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                    Go back to Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
