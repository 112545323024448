import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    clearUserState: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },
});

export const { setUser, clearUserState } = userSlice.actions;

export const fetchMe = () => async (dispatch) => {
  try {
    const response = await apiSlice.endpoints.getMe.initiate();
    dispatch(setUser(response.data?.data));
  } catch (error) {
    console.error(error);
  }
};

export default userSlice.reducer;
