import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import dataReducer from "./dataSlice"; // Import the data slice
import quizReducer from "./quizSlice"; // Import the data slice
import userReducer from "./userSlice";

export const store = configureStore({
  reducer: {
    data: dataReducer,
    quiz: quizReducer,
    user: userReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true, // Enable Redux DevTools
});
