import React, { useState, useEffect } from "react";
import { useLazyGetWithdrawHistoriesQuery } from "../api/apiSlice";
import LoadingSpinner from "../components/LoadingSpinner";
import Swal from "sweetalert2";
import Select from "react-select";
import { FaMobileAlt, FaRegCreditCard, FaQrcode, FaSearch } from "react-icons/fa";

const WithdrawalHistoryPage = () => {
    const token = localStorage.getItem("token");

    const [getHistory, { data, isLoading }] = useLazyGetWithdrawHistoriesQuery();
    const [filter, setFilter] = useState("All");

    useEffect(() => {
        getHistory({});
    }, [getHistory]);

    if (!token) {
        Swal.fire({
            icon: "warning",
            title: "Login Required",
            text: "Please log in to view your withdrawal history.",
        });
        return null;
    }

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <LoadingSpinner />
            </div>
        );
    }

    const maskPaymentDetails = (paymentType, details) => {
        if (!details) return "No details available";

        if (paymentType === "MobileNumber") {
            return details.length === 10
                ? `${details.slice(0, 3)}******${details.slice(-2)}`
                : "Invalid Mobile Number";
        } else if (paymentType === "UPI") {
            const atIndex = details.indexOf("@");
            if (atIndex === -1) return "Invalid UPI ID";
            return details.slice(0, atIndex - 2) + "****" + details.slice(atIndex + 8);
        } else if (paymentType === "QRCode") {
            return "QR Code Attached";
        }
        return "Unknown Payment Type";
    };

    const filteredData =
        filter === "All"
            ? data?.data
            : data?.data.filter(
                (history) =>
                    history.status === filter ||
                    history.payment_type === filter
            );

    if (!data || data?.data?.length === 0) {
        return (
            <div
                className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-800 via-gray-900 to-black"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <h1 className="text-3xl sm:text-4xl font-semibold text-white mb-6 drop-shadow-lg">
                    Withdrawal History
                </h1>
                <p className="text-lg text-gray-300 text-center max-w-lg">
                    {token
                        ? "You don't have any withdrawal history yet. Once you initiate a withdrawal, the details will appear here."
                        : "Please log in to view your withdrawal history."}
                </p>
                <button
                    onClick={() => window.location.reload()}
                    className="mt-6 px-6 py-2 bg-teal-500 text-white font-semibold rounded-lg shadow-md hover:bg-teal-600 transition duration-300 transform hover:scale-105"
                >
                    Refresh
                </button>
            </div>
        );
    }

    const filterOptions = [
        { value: "All", label: "All" },
        { value: "Approved", label: "Approved" },
        { value: "Rejected", label: "Rejected" },
        { value: "Pending", label: "Pending" },
        { value: "MobileNumber", label: "Mobile Number" },
        { value: "UPI", label: "UPI" },
        { value: "QRCode", label: "QRCode" },
    ];

    const handleFilterChange = (selectedOption) => {
        setFilter(selectedOption.value);
    };

    return (
        <div
            className="min-h-screen bg-gray-900 flex flex-col items-center py-6"
            style={{
                backgroundImage: `linear-gradient(to bottom right, #1f2937, #111827), url('./element.png')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <h1 className="text-3xl sm:text-4xl font-semibold text-white mb-6 drop-shadow-lg">
                Withdrawal History
            </h1>

            {/* Filter Options */}
            <div className="mb-6 w-full px-4 sm:px-6 lg:px-8">
                <div className="hidden sm:flex flex-wrap gap-3 justify-center">
                    {filterOptions.map((option) => (
                        <button
                            key={option.value}
                            className={`px-4 py-2 rounded-full text-sm font-medium transition 
                            ${filter === option.value
                                    ? "bg-teal-500 text-white shadow-md transform hover:scale-105"
                                    : "bg-gray-700 text-teal-400 hover:bg-teal-600 hover:text-white"
                                }`}
                            onClick={() => setFilter(option.value)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>

                {/* Dropdown for Mobile View using React Select */}
                <div className="sm:hidden flex justify-center">
                    <Select
                        options={filterOptions}
                        value={filterOptions.find((option) => option.value === filter)}
                        onChange={handleFilterChange}
                        className="w-full"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: "#2d3748",
                                borderColor: "#4fd1c5",
                                borderRadius: "0.375rem",
                                padding: "0.5rem",
                                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: "#81e6d9",
                            }),
                            menu: (provided) => ({
                                ...provided,
                                backgroundColor: "#2d3748",
                                borderRadius: "0.375rem",
                                borderColor: "#4fd1c5",
                                boxShadow: "0 2px 15px rgba(0, 0, 0, 0.15)",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? "#4fd1c5" : "transparent",
                                color: state.isSelected ? "#2d3748" : "#cbd5e0",
                                padding: "10px",
                                transition: "background-color 0.3s ease",
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: "#81e6d9",
                            }),
                            input: (provided) => ({
                                ...provided,
                                color: "#ffffff",
                            }),
                        }}
                        placeholder="Select a filter..."
                    />
                </div>
            </div>

            <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8 space-y-6">
                {filteredData.length === 0 ? (
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center text-gray-300 bg-gray-800 p-8 rounded-xl shadow-lg">
                        <div className="flex items-center mb-4">
                            <FaSearch className="w-12 h-12 text-teal-400 mr-4 animate-pulse" />
                            <p className="text-lg sm:text-xl font-semibold text-center text-teal-500">
                                No records match your selected filter.
                            </p>
                        </div>
                        <p className="text-sm sm:text-base text-gray-400 text-center mt-2">
                            Try adjusting the filter or clear it to see all records.
                        </p>
                    </div>
                ) : (
                    filteredData.map((history) => (
                        <div
                            key={history.id}
                            className="bg-gray-800 shadow-lg rounded-lg p-6 border-l-4 transition duration-300 ease-in-out "
                            style={{
                                borderColor:
                                    history.status === "Approved"
                                        ? "green"
                                        : history.status === "Rejected"
                                            ? "red"
                                            : "orange",
                            }}
                        >
                            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
                                <h2 className="text-xl sm:text-2xl font-semibold text-teal-300">
                                    {history.payment_type === "MobileNumber" && (
                                        <FaMobileAlt className="inline mr-2" />
                                    )}
                                    {history.payment_type === "UPI" && (
                                        <FaRegCreditCard className="inline mr-2" />
                                    )}
                                    {history.payment_type === "QRCode" && (
                                        <FaQrcode className="inline mr-2" />
                                    )}
                                    {history.payment_type}
                                </h2>
                                <span
                                    className={`mt-2 sm:mt-0 text-sm font-semibold 
                                    ${history.status === "Approved"
                                            ? "text-green-400"
                                            : history.status === "Rejected"
                                                ? "text-red-400"
                                                : "text-orange-400"
                                        }`}
                                >
                                    {history.status}
                                </span>
                            </div>

                            <div className="text-gray-300 mb-4">
                                <div className="mb-2">
                                    <span className="font-medium">Coins:</span> {history.coins}
                                </div>
                                <div className="mb-2">
                                    <span className="font-medium">Amount:</span> RS.{history.amount}
                                </div>
                                {history.note && (
                                    <div className="mb-2">
                                        <span className="font-medium">Note:</span> {history.note}
                                    </div>
                                )}
                            </div>

                            <div className="mb-4 text-white">
                                <span className="font-medium">Payment Details:</span>{" "}
                                {maskPaymentDetails(history.payment_type, history.payment_details)}
                            </div>

                            {history.image && (
                                <div className="mb-4">
                                    <img
                                        src={history.image}
                                        alt="Receipt"
                                        className="w-full max-w-xs h-auto border border-gray-200 rounded-md shadow-md"
                                    />
                                </div>
                            )}

                            <div className="text-gray-400 text-sm">
                                <span className="font-medium">Requested On:</span>{" "}
                                {new Date(history.created_at).toLocaleString()}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default WithdrawalHistoryPage;
