import React from 'react';

const CoinInputForm = ({ coinAmount, setCoinAmount, message, messageType, handleConversion, isLoading }) => {
    return (
        <div className="mb-4">
            <label className="block text-sm text-gray-100 mb-2">
                Coin Amount
            </label>
            <input
                type="number"
                value={coinAmount}
                onChange={(e) => setCoinAmount(e.target.value)}
                className={`w-full p-2 rounded-md bg-gray-800 border ${messageType === "error" ? "border-red-500 text-red-500" : "border-gray-500 text-white"} focus:outline-none focus:border-teal-500 transition`}
                placeholder="Enter coin amount"
            />
            {message && (
                <p className={`md:text-md text-sm mt-2 ${messageType === "error" ? "text-red-500" : "text-teal-400"}`}>
                    {message}
                </p>
            )}
            <button
                onClick={handleConversion}
                className={`w-full py-2 px-4 mt-4 rounded-lg text-sm font-bold transition ${isLoading ? "bg-gray-600 cursor-not-allowed" : "bg-teal-500 hover:bg-teal-600"}`}
                disabled={isLoading}
            >
                {isLoading ? "Converting..." : "Convert to Rupees"}
            </button>
        </div>
    );
};

export default CoinInputForm;
