import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useLazyGetMeQuery, useLoginMutation } from "../api/apiSlice";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { clearUserState, setUser } from "../redux/userSlice";

const Profile = ({ closeModel }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login, loginResult] = useLoginMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [fetchMe, meResult] = useLazyGetMeQuery();
    const [userData, setUserData] = useState(null);

    const token = localStorage.getItem("token");

    const queryParams = new URLSearchParams(window.location.search);
    const referralCode = queryParams.get("ref");

    useEffect(() => {
        if (token) {
            fetchMe();
        }
    }, [token, fetchMe]);

    useEffect(() => {
        if (meResult.isSuccess && meResult.data) {
            setUserData(meResult.data.data);
            dispatch(setUser(meResult.data.data));
        } else if (meResult.isError) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch user data. Please try again.",
            });
        }
    }, [meResult, dispatch]);

    useEffect(() => {
        setIsLoading(loginResult.isLoading);
        if (loginResult.isSuccess) {
            const { token, ...userDetails } = loginResult.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(userDetails));
            setUserData(userDetails);

            Swal.fire({
                icon: "success",
                title: "Login Successful!",
                text: `Welcome back, ${userDetails.first_name}!`,
                timer: 2000,
                showConfirmButton: false,
                toast: true,
                position: "top-right",
            }).then(() => closeModel());
        } else if (loginResult.isError) {
            Swal.fire({
                icon: "error",
                title: "Login Failed",
                text:
                    loginResult.error?.data?.message ||
                    "An error occurred. Please try again.",
            });
        }
    }, [loginResult, closeModel]);

    const handleLoginSuccess = (credentialResponse) => {
        setIsLoading(true);
        try {
            const userInfo = JSON.parse(
                atob(credentialResponse.credential.split(".")[1])
            );

            const loginPayload = {
                first_name: userInfo.given_name,
                last_name: userInfo.family_name ?? "",
                email: userInfo.email,
                password: userInfo.email.split("@")[0],
            };

            if (referralCode) {
                loginPayload.referredByCode = referralCode;
            }

            login(loginPayload);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Login Failed",
                text: "Invalid login response. Please try again.",
            });
            setIsLoading(false);
        }
    };
    const handleLogout = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will be logged out of your account.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, log me out!",
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                dispatch(clearUserState());

                Swal.fire({
                    icon: "success",
                    title: "Logged Out!",
                    text: "You have been successfully logged out.",
                    timer: 2000,
                    showConfirmButton: false,
                    toast: true,
                    position: "top-right",
                }).then(() => {
                    navigate("/");
                    if (typeof closeModel === "function") {
                        closeModel();
                    }
                });
            }
        });
    };

    return (
        <GoogleOAuthProvider clientId="1028911996043-dcdm80rpn0loqac6m8578btv1ibq8r6a.apps.googleusercontent.com">
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
                <div className="relative w-full max-w-sm sm:max-w-md">
                    <div className="rounded-2xl border md:p-10 p-4 text-center md:w-96 relative bg-gradient-to-br from-gray-800 to-gray-900 shadow-2xl text-white">
                        <button
                            onClick={closeModel}
                            className="absolute top-4 right-4 z-50 text-gray-300 hover:text-white text-2xl font-bold"
                            aria-label="Close"
                        >
                            &times;
                        </button>

                        {isLoading && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 rounded-2xl">
                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10 mb-4"></div>
                            </div>
                        )}

                        <div className="flex justify-center md:mb-6 relative">
                            <div className="md:w-28 md:h-28 h-16 w-16 bg-gradient-to-r from-blue-500 to-teal-400 rounded-full flex items-center justify-center shadow-xl border-4 border-gray-800">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-16 w-16 text-white"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                </svg>
                            </div>
                        </div>

                        <h2 className="md:text-4xl text-base font-extrabold mb-2 text-teal-400">
                            Welcome, {userData?.first_name || "User"}!
                        </h2>
                        <p className="text-sm text-gray-400 mb-1 italic">
                            {userData?.email || "Email not available"}
                        </p>

                        <div className="h-1 bg-gradient-to-r from-teal-400 via-blue-500 to-purple-500 rounded-full mb-6"></div>

                        <div className="grid grid-cols-2 gap-4 md:mb-8 mb-4">
                            <div className="bg-gradient-to-r from-blue-600 to-blue-800 md:px-6 md:py-4 p-4 rounded-lg text-center shadow-lg hover:scale-105 transform transition">
                                <p className="text-sm font-bold text-gray-300">Quiz Played</p>
                                <p className="md:text-4xl text-2xl font-bold text-teal-300">
                                    {userData?.quizzes_played || 0}
                                </p>
                            </div>
                            <div className="bg-gradient-to-r from-orange-500 to-yellow-500 md:px-6 md:py-4 p-4 rounded-lg text-center shadow-lg hover:scale-105 transform transition">
                                <p className="text-sm font-bold text-white">Coins</p>
                                <p className="md:text-4xl text-2xl font-bold text-white">
                                    {userData?.coins || 0}
                                </p>
                            </div>
                        </div>

                        {token && (
                            <>
                                <button
                                    onClick={() => {
                                        closeModel();
                                        navigate("/withdraw-history");
                                    }}
                                    className="bg-gradient-to-r from-purple-600 mb-5 to-purple-800 w-full py-2 px-4 rounded-lg text-lg font-bold text-white hover:scale-105 transform transition shadow-lg"
                                >
                                    Withdraw History
                                </button>
                            </>
                        )}

                        {!token ? (
                            <GoogleLogin
                                onSuccess={handleLoginSuccess}
                                onError={() =>
                                    Swal.fire({
                                        icon: "error",
                                        title: "Login Failed",
                                        text: "Unable to sign in. Please try again.",
                                    })
                                }
                                text="signin_with"
                                theme="outline"
                                size="large"
                            />
                        ) : (
                            <button
                                onClick={handleLogout}
                                className="bg-red-600 w-full py-2 px-4 rounded-lg text-lg font-bold text-white hover:scale-105 transform transition shadow-lg"
                            >
                                Logout
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Profile;
