import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Confetti from "react-confetti";
import { useLazyGetMeQuery, useQuizPlayedByUserMutation } from "../api/apiSlice";
import { setUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const ResultPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();

    const { correctAnswers = 0, totalQuestions = 0, quizId = 0, score } = location.state || {};

    const [quizPlayedByUser, quizResult] = useQuizPlayedByUserMutation();
    const [triggerFetchMe, { data: meData, isSuccess }] = useLazyGetMeQuery();

    useEffect(() => {
        if (isSuccess) {
            dispatch(setUser(meData?.data))
        }
    }, [isSuccess, meData, dispatch]);

    useEffect(() => {
        if (quizResult.isSuccess) {

            if (!quizResult.data.success) {
                const message = quizResult?.data?.message || "You have completed the quiz!";
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'info',
                    title: message,
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }

            triggerFetchMe()
        }
    }, [quizResult, triggerFetchMe])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (quizId && score) {
            (async () => {
                try {
                    await quizPlayedByUser({ quiz_id: quizId, coins: score }).unwrap();
                } catch (err) {
                    console.error("Error recording quiz play:", err);
                }
            })();
        }
    }, [quizId, score, quizPlayedByUser]);
    return (
        <div className="min-h-screen h-full overflow-hidden flex items-center flex-col mt-8 text-white p-4 md:p-6">
            <div className="overflow-hidden w-[90%] -top-1 h-[100vh] absolute">
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                />
            </div>

            <div className="text-center mb-6 md:mb-8">
                <h1 className="text-2xl md:text-5xl font-bold mb-3 md:mb-4 text-teal-400 animate-bounce">
                    🎉 Congratulations! 🎉
                </h1>
                <p className="text-sm md:text-lg text-gray-200">
                    You’ve successfully completed the quiz.
                </p>
            </div>

            <div className="bg-gradient-to-r from-teal-500 to-green-500 p-4 md:p-6 rounded-xl shadow-lg text-center w-full max-w-md md:max-w-lg border border-teal-300">
                <p className="text-lg md:text-3xl font-bold mb-4 md:mb-6 text-gray-100">
                    Your Results
                </p>
                <div className="text-base md:text-xl mb-3 md:mb-4">
                    <span className="font-semibold text-gray-100">Correct Answers:</span>{" "}
                    <span className="font-bold text-yellow-300">{correctAnswers}</span> / {totalQuestions}
                </div>
                <div className="text-base md:text-xl">
                    <span className="font-semibold text-gray-100">Total Coins Earned:</span>{" "}
                    <span className="font-bold text-yellow-400">{score}</span>
                </div>
            </div>

            <div className="mt-6 md:mt-8 flex flex-col items-center">
                <div className="animate-pulse text-4xl md:text-6xl hidden md:block">
                    🏅
                </div>
                <p className="mt-3 md:mt-4 text-sm md:text-xl font-medium text-gray-100 text-center">
                    Great Job! You've achieved excellence!
                </p>
            </div>

            <div className="mt-6 md:mt-8 flex gap-2 md:gap-4">
                <button
                    onClick={() => navigate("/")}
                    className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white px-4 py-2 md:px-6 md:py-3 rounded-lg font-bold hover:scale-105 transform transition shadow-lg text-sm md:text-base"
                >
                    Go Home
                </button>
            </div>
        </div>
    );
};

export default ResultPage;
