import CryptoJS from "crypto-js";

const DECRYPTION_KEY =
  process.env.REACT_APP_DECRYPTION_KEY || "12345678901234567890123456789012"; // 32-byte key

export const decryptData = (encryptedData) => {
  try {
    const [ivHex, encryptedHex] = encryptedData.split(":");
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);
    const key = CryptoJS.enc.Utf8.parse(DECRYPTION_KEY);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
      iv,
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};
