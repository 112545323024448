import { FaCoins } from "react-icons/fa";
import { useSelector } from "react-redux";

const QuizCard = ({ quiz, openModal }) => {
    const categories = useSelector((state) => state.data.categories);
    const category = categories.find((cat) => cat.id === quiz.category_id);

    return (
        <div
            onClick={() => openModal(quiz, category)}
            className="bg-gradient-to-br from-[#3b4f77] cursor-pointer to-[#2c3e52] text-white rounded-xl shadow-lg md:p-5 p-2 flex flex-col justify-between transform transition-all duration-300 hover:scale-105 hover:shadow-2xl hover:bg-gradient-to-br hover:from-[#5b6f8a] hover:to-[#3c4e63]">
            {/* Category Image and Name */}
            <div className="flex flex-col md:flex-row gap-1 items-center justify-between mb-4">
                {category && category.image && (
                    <img
                        src={category.image}
                        alt={category.name}
                        className="w-10 h-10 rounded-full object-cover border-2 border-gray-600"
                    />
                )}
                <span className="text-xs font-medium text-gray-400 bg-gray-800 px-2 py-1 rounded-lg">
                    {category ? category.name : "Category"}
                </span>
            </div>

            {/* Quiz Title */}
            <div className="text-center mb-4">
                <h3 className="md:text-lg text-xs font-semibold text-gray-100">
                    {quiz.title}
                </h3>
            </div>

            {/* Total Prize */}
            <div className="flex flex-col md:flex-row md:gap-2 gap-1 items-center justify-center text-base md:mb-4">
                <span className="font-medium text-xs md:text-lg text-yellow-400">Play & Win</span>
                <div className="flex items-center text-yellow-400">
                    <FaCoins className="animate-bounce" />
                    <span className="ml-2">₹{quiz.total_price}</span>
                </div>
            </div>
        </div>
    );
};

export default QuizCard;
