import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quizDetails: null,
  quizQuestions: [],
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setQuizDetails(state, action) {
      state.quizDetails = action.payload;
    },
    setQuizQuestions(state, action) {
      state.quizQuestions = action.payload;
    },
  },
});

export const { setQuizDetails, setQuizQuestions } = quizSlice.actions;

export default quizSlice.reducer;
