import React from "react";
import { FaMobileAlt, FaQrcode, FaCreditCard } from "react-icons/fa"; // Import icons for payment methods

const PaymentMethodSelector = ({ onPaymentMethodSelect, activeMethod }) => {

    // Handle method selection and set the active method
    const handleMethodSelect = (methodValue) => {
        onPaymentMethodSelect(methodValue); // Pass the selected method to parent component
    };

    return (
        <div className="mt-8 max-w-md mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-3 md:gap-6 gap-3">
                {[
                    { label: "Mobile Number", icon: <FaMobileAlt />, value: "MobileNumber" },
                    { label: "UPI", icon: <FaCreditCard />, value: "UPI" },
                    { label: "QR Code", icon: <FaQrcode />, value: "QRCode" },
                ].map((method) => (
                    <button
                        key={method.value}
                        onClick={() => handleMethodSelect(method.value)} // Handle method click
                        className={`md:py-4 md:px-6 p-2 rounded-lg hover:to-teal-700 text-white text-lg flex items-center justify-center md:gap-3 gap-1 shadow-md transform  transition-transform focus:outline-none focus:ring-2 border-2  ${activeMethod === method.value
                            ? 'border-white bg-transparent' // Highlight active method with border
                            : 'border-transparent  bg-gradient-to-r from-teal-400 to-teal-600 hover:from-teal-500'
                            }`}
                        aria-label={`Select ${method.label}`}
                    >
                        <span className="md:text-2xl">{method.icon}</span>
                        <span>{method.label}</span>
                    </button>
                ))}
            </div>
        </div >
    );
};

export default PaymentMethodSelector;
