import React, { useEffect } from "react";
import Profile from "./Profile";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useLazyGetMeQuery } from "../api/apiSlice";
import { setUser } from "../redux/userSlice";
import { FiMenu, FiX, FiUser, FiDollarSign, FiCreditCard, FiGift } from "react-icons/fi"; // Import the new icon for referral

const Header = ({ title }) => {
    const [isProfileOpen, setIsProfileOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const dispatch = useDispatch();
    const location = useLocation();  // To access the URL query params

    const [triggerFetchMe, { data: meData, isError, error, isSuccess }] = useLazyGetMeQuery();
    const user = useSelector((state) => state.user);
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            triggerFetchMe();
        }
    }, [token, triggerFetchMe]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setUser(meData?.data));
        }
    }, [isSuccess, meData, dispatch]);

    useEffect(() => {
        if (isError) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error?.data?.message || "Failed to fetch user data. Please try again.",
            });
        }
    }, [isError, error]);

    // Check if the URL contains the 'ref' query parameter and open the Profile Modal
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has("ref")) {
            setIsProfileOpen(true);
        }
    }, [location.search]);  // Re-run when the URL changes

    return (
        <>
            <header className="sticky top-0 z-50 bg-gray-800 shadow-md">
                <div className="container mx-auto px-4 py-3 flex items-center justify-between">
                    {/* Title */}
                    <Link to="/" className="text-xl sm:text-2xl font-extrabold tracking-wide text-white flex items-center">
                        {/* Logo with proper size */}
                        <img
                            src="./logo.png"
                            alt="logo"
                            className="h-10 sm:h-12 mr-2"  // Adjust the height as needed
                        />
                    </Link>

                    {/* Hamburger Menu */}
                    <button
                        aria-label="menu"
                        onClick={() => setMenuOpen(!menuOpen)}
                        className="sm:hidden text-white focus:outline-none"
                    >
                        {menuOpen ? <FiX className="h-6 w-6" /> : <FiMenu className="h-6 w-6" />}
                    </button>

                    {/* Desktop Menu */}
                    <nav className="hidden sm:flex items-center space-x-6">
                        {/* Coins Display */}
                        <div className="bg-gray-700 md:px-4 p-2 md:py-2 rounded-lg shadow text-white font-medium flex items-center md:space-x-2">
                            <FiDollarSign />
                            <span>Coins: {user?.data?.coins ?? 0}</span>
                        </div>

                        {/* Referral Button */}
                        <Link
                            to="/referral" // Link to the referral page
                            className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg shadow-md transition flex items-center space-x-2"
                        >
                            <FiGift />
                            <span>Referral</span>
                        </Link>

                        {/* Profile Button */}
                        <button
                            onClick={() => setIsProfileOpen(true)}
                            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow-md transition flex items-center space-x-2"
                        >
                            <FiUser />
                            <span>{token ? "Profile" : "Login"}</span>
                        </button>

                        {/* Withdrawal Button */}
                        {token && (
                            <Link
                                to="/withdrawal"
                                className="bg-teal-600 hover:bg-teal-700 text-white px-4 py-2 rounded-lg shadow-md transition flex items-center space-x-2"
                            >
                                <FiCreditCard />
                                <span>Withdrawal</span>
                            </Link>
                        )}
                    </nav>
                </div>

                {/* Mobile Menu */}
                {menuOpen && (
                    <div className="fixed h-screen overflow-y-scroll w-full top-0 right-0 bg-gradient-to-br from-gray-800 via-gray-900 to-black bg-opacity-95 flex p-2 items-start justify-between z-50">
                        {/* Navigation Content */}
                        <nav className="flex flex-col items-center space-y-4 p-2">
                            {/* Coins Display */}
                            <div className="flex items-center bg-gray-700 px-4 py-2 rounded-lg shadow text-white font-medium text-md space-x-3">
                                <FiDollarSign />
                                <span>Coins: {user?.data?.coins ?? 0}</span>
                            </div>

                            {/* Referral Button */}
                            {token && (
                                <Link
                                    to="/referral" // Link to the referral page
                                    className="w-full max-w-xs bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg shadow-md transition text-md flex items-center justify-center space-x-3"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <FiGift />
                                    <span>Referral</span>
                                </Link>
                            )}

                            {/* Profile Button */}
                            <button
                                onClick={() => {
                                    setIsProfileOpen(true);
                                    setMenuOpen(false);
                                }}
                                className="w-full max-w-xs bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow-md transition text-md flex items-center justify-center space-x-3"
                            >
                                <FiUser />
                                <span>{token ? "Profile" : "Login"}</span>
                            </button>

                            {/* Withdrawal Button */}
                            {token && (
                                <Link
                                    to="/withdrawal"
                                    className="w-full max-w-xs bg-teal-600 hover:bg-teal-700 text-white px-4 py-2 rounded-lg shadow-md transition text-md flex items-center justify-center space-x-3"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    <FiCreditCard />
                                    <span>Withdrawal</span>
                                </Link>
                            )}
                        </nav>
                        {/* Close Button */}
                        <button
                            onClick={() => setMenuOpen(false)}
                            className=" text-white bg-gray-700 hover:bg-gray-600 p-2 rounded-full shadow-md transition"
                        >
                            <FiX className="h-5 w-5" />
                        </button>
                    </div>
                )}
            </header>

            {/* Profile Modal */}
            {isProfileOpen && <Profile closeModel={() => setIsProfileOpen(false)} />}
        </>
    );
};

export default Header;
