/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuizDetails, setQuizQuestions } from "../redux/quizSlice";
import { useLazyGetQuestionsByQuizQuery } from "../api/apiSlice";
import LoadingSpinner from "../components/LoadingSpinner";
import { decryptData } from "../utils/decryptData";
const QuizPlay = () => {
    const { quizId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [getQuizQuestions, { isFetching, isError, error }] =
        useLazyGetQuestionsByQuizQuery();

    const quizDetails = useSelector((state) => state.quiz.quizDetails);
    const quizQuestions = useSelector((state) => state.quiz.quizQuestions);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [timer, setTimer] = useState(10);
    const [quizPlayed, setQuizPlayed] = useState(false);
    const [score, setScore] = useState(0);
    const currentQuestion = quizQuestions[currentQuestionIndex];
    useEffect(() => {
        if (isError && error.data.message === "You have already played this quiz.")
            setQuizPlayed(true);
    }, [isError, error]);
    useEffect(() => {
        const getQuestions = async () => {
            try {
                const response = await getQuizQuestions(quizId);
                if (response?.data?.encryptedData) {
                    const decryptedResponse = decryptData(response.data.encryptedData);
                    if (decryptedResponse) {
                        dispatch(setQuizQuestions(decryptedResponse.data.questions));
                        dispatch(setQuizDetails(decryptedResponse.data.quizDetails));
                    } else {
                        console.error("Decryption failed.");
                    }
                } else {
                    console.error("No encrypted data found in response.");
                }
            } catch (error) {
                console.log("Failed to fetch quiz questions:", error);
            }
        };

        if (quizId && !quizPlayed) {
            getQuestions();
        }
    }, [quizId, quizPlayed]);

    useEffect(() => {
        if (timer === 0 && !isAnswered) {
            handleNextQuestion();
        }

        const timerInterval = setInterval(() => {
            if (timer > 0 && !isAnswered) {
                setTimer(timer - 1);
            }
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [timer, isAnswered]);
    useEffect(() => {
        if (currentQuestionIndex === quizQuestions.length - 1) {
            navigate("/results", {
                state: {
                    correctAnswers,
                    totalQuestions: quizQuestions.length,
                    quizId,
                    score,
                },
            });
        }
    }, [
        currentQuestionIndex,
        correctAnswers,
        quizQuestions.length,
        quizId,
        navigate,
        score,
    ]);

    const handleAnswerSelection = (answer) => {
        setSelectedAnswer(answer);
        setIsAnswered(true);

        if (answer === currentQuestion.correct) {
            setScore((prevScore) => prevScore + 100);
            setCorrectAnswers((prev) => prev + 1);
        } else {
            setScore((prevScore) => prevScore - 10);
        }

        setTimeout(() => {
            handleNextQuestion();
        }, 1000);
    };

    const handleNextQuestion = () => {
        if (quizPlayed) {
            return;
        }
        if (currentQuestionIndex < quizQuestions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedAnswer(null);
            setIsAnswered(false);
            setTimer(10);
        }
    };

    const handleBackButtonClick = () => {
        navigate(-1);
    };

    if (quizPlayed) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-800 text-white">
                <div className="text-center p-6 bg-teal-500 rounded-lg shadow-lg">
                    <h2 className="text-xl font-bold mb-4">
                        You have already played this quiz!
                    </h2>
                    <p className="text-lg mb-4">
                        Unfortunately, you can't play this quiz again.
                    </p>
                    <button
                        onClick={() => navigate("/")}
                        className="bg-teal-600 px-6 py-3 rounded-lg text-white hover:bg-teal-700"
                    >
                        Go Back to Home
                    </button>
                </div>
            </div>
        );
    }

    if (isFetching) {
        return (
            <div className="flex justify-center items-center h-screen">
                <LoadingSpinner />
            </div>
        );
    }
    if (quizQuestions?.length === 0) {
        return (
            <div className="flex justify-center items-center h-screen">
                <LoadingSpinner />
            </div>
        );
    }

    if (!quizPlayed && !isFetching && quizQuestions?.length !== 0) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-800 to-gray-900">
                <div className="container w-full md:w-3/4 lg:w-1/2 mx-auto px-4 py-8">
                    <div className="flex items-center justify-between mb-6">
                        <button
                            onClick={handleBackButtonClick}
                            className="bg-teal-500 text-white px-4 py-2 rounded-lg font-bold hover:bg-teal-600 transition shadow-lg"
                        >
                            Back
                        </button>
                    </div>
                    {quizDetails && (
                        <h1 className="text-2xl md:text-3xl font-bold text-center text-white mb-4">
                            {quizDetails.title}
                        </h1>
                    )}

                    {/* Progress Bar */}
                    <div className="mb-6">
                        <div className="relative">
                            <div className="flex mb-2 items-center justify-between">
                                <span className="text-xs font-semibold py-1 px-2 text-teal-600 bg-teal-200 rounded-full">
                                    Progress
                                </span>
                                <span className="text-xs font-semibold py-1 px-2 text-teal-600 bg-teal-200 rounded-full">
                                    {Math.round(
                                        ((currentQuestionIndex + 1) / quizQuestions.length) * 100
                                    )}
                                    %
                                </span>
                            </div>
                            <div className="relative w-full h-2 bg-teal-200 rounded-lg">
                                <div
                                    className="absolute h-2 bg-teal-500 rounded-lg"
                                    style={{
                                        width: `${((currentQuestionIndex + 1) / quizQuestions.length) * 100
                                            }%`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Timer */}
                    <div className="text-center mb-6">
                        <p
                            className={`text-lg md:text-xl font-bold ${timer <= 5 ? "text-red-500" : "text-white"
                                }`}
                        >
                            Time Left: {timer}s
                        </p>
                    </div>
                    {/* Question Number */}
                    <p className="text-xl text-white text-center mb-4">
                        Question {currentQuestionIndex + 1} of {quizQuestions.length}
                    </p>

                    {/* Question */}
                    <div className="bg-teal-500 text-white p-4 md:p-6 rounded-lg shadow-lg mb-6">
                        <h2 className="text-lg md:text-2xl font-semibold text-center">
                            {currentQuestion ? currentQuestion.question : "Loading..."}
                        </h2>
                    </div>

                    {/* Coins Earned */}
                    <div className="text-center text-white mb-6">
                        <p className="text-sm md:text-lg font-bold">Score: {score}</p>
                    </div>

                    {/* Choices */}
                    <div className="grid grid-cols-2 gap-4">
                        {currentQuestion &&
                            currentQuestion.answer.map((choice, index) => {
                                let buttonClass =
                                    "py-2 px-4 rounded-lg text-lg transition text-white font-semibold";

                                if (isAnswered) {
                                    if (choice === currentQuestion.correct) {
                                        buttonClass += " bg-green-500 text-white";
                                    } else if (
                                        choice === selectedAnswer &&
                                        selectedAnswer !== currentQuestion.correct
                                    ) {
                                        buttonClass += " bg-red-500 text-white";
                                    }
                                } else {
                                    buttonClass += " bg-teal-500 hover:bg-teal-600 text-white";
                                }

                                return (
                                    <button
                                        key={index}
                                        onClick={() => handleAnswerSelection(choice)}
                                        className={buttonClass}
                                        disabled={isAnswered}
                                    >
                                        {choice}
                                    </button>
                                );
                            })}
                    </div>

                    {/* Feedback */}
                    {isAnswered && (
                        <div className="mt-6">
                            {selectedAnswer === currentQuestion.correct ? (
                                <p className="text-green-500 font-semibold text-center">
                                    Correct!
                                </p>
                            ) : (
                                <p className="text-red-500 font-semibold text-center">
                                    Incorrect. The correct answer is: {currentQuestion.correct}
                                </p>
                            )}
                        </div>
                    )}

                    {/* Next Button */}
                    <div className="flex justify-center mt-6">
                        <button
                            onClick={handleNextQuestion}
                            className="bg-teal-500 text-white px-4 py-2 md:px-6 md:py-3 rounded-lg font-bold hover:bg-teal-600 transition shadow-lg"
                            disabled={!isAnswered}
                        >
                            {currentQuestionIndex < quizQuestions.length - 1
                                ? "Next Question"
                                : "Finish Quiz"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};

export default QuizPlay;
