import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetMeQuery } from "../api/apiSlice";
import { setUser } from "../redux/userSlice";
import { useLazyGetReferredUsersQuery } from "../api/apiSlice";
import LoadingSpinner from "../components/LoadingSpinner";
import Profile from "../components/Profile";
import { FaCoins, FaUserFriends } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";

const ReferralPage = () => {
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const [referralLink, setReferralLink] = useState("");
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [triggerFetchMe, { data: meData, isSuccess }] = useLazyGetMeQuery();
    const [
        getReferUser,
        { data: referredUsersData, error: referredUsersError, isLoading: referredUsersLoading },
    ] = useLazyGetReferredUsersQuery();

    useEffect(() => {
        if (token) {
            triggerFetchMe();
        }
    }, [token, triggerFetchMe]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setUser(meData?.data));
        }
    }, [isSuccess, meData, dispatch]);

    useEffect(() => {
        if (user?.id) {
            getReferUser(user?.id);
            setReferralLink(`${window.location.origin}?ref=${user.referral_code}`);
        }
    }, [user, getReferUser]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(referralLink);
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000); // Hide after 2 seconds
    };

    const handleOpenModal = () => {
        setShowModal(true); // Show modal when "Log In" is clicked
    };

    const handleCloseModal = () => {
        setShowModal(false); // Hide modal
    };

    if (referredUsersLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="container mx-auto md:p-6 p-4 md:space-y-8 space-y-4">
            {/* Page Title */}
            <h1 className="md:text-4xl text-xl font-bold text-center text-white">
                🎉 Referral Program: Earn Extra Coins!
            </h1>
            <p className="text-center text-gray-300 md:text-lg text-sm max-w-3xl mx-auto">
                Share your referral link with friends and earn coins for every successful referral. Start earning now!
            </p>

            {/* If there's no token */}
            {!token ? (
                <div className="bg-gray-700 md:p-8 p-4 rounded-lg text-center space-y-6 shadow-lg">
                    <h2 className="md:text-3xl text-lg flex items-center justify-center gap-2 text-white font-semibold">
                        Get Extra Coins! <FaCoins className="text-yellow-400 animate-pulse" />
                    </h2>
                    <p className="text-base text-gray-200">
                        For a limited time, earn{" "}
                        <span className="text-green-400 font-bold">50% more coins</span> for each successful referral!
                    </p>
                    <button
                        onClick={handleOpenModal}
                        className="bg-teal-600 hover:bg-teal-700 text-white font-semibold md:py-3 md:px-8 p-2 rounded-lg shadow-md transition-transform transform hover:scale-105"
                    >
                        Log In
                    </button>
                </div>
            ) : (
                <>
                    {/* Referral Link Section */}
                    <div className="bg-gray-700  p-6 rounded-lg shadow-lg space-y-4">
                        <h2 className="md:text-2xl text-lg font-semibold text-white flex items-center gap-2">
                            <FiCopy className="text-green-400" /> Your Referral Link
                        </h2>
                        <p className="md:text-sm text-xs text-gray-400">
                            Share this link with your friends to earn rewards:
                        </p>
                        <div className="flex flex-col sm:flex-row items-center md:space-y-0 space-y-2 md:space-x-4">
                            <input
                                type="text"
                                value={referralLink}
                                readOnly
                                className="bg-gray-900 text-gray-300 p-3 rounded-lg w-full sm:w-auto flex-1"
                            />
                            <button
                                onClick={handleCopyLink}
                                className="bg-green-600 hover:bg-green-700 text-white md:px-6 md:py-3 p-3 rounded-lg transition-transform transform  flex items-center gap-2"
                            >
                                <FiCopy /> Copy Link
                            </button>
                        </div>
                        {showCopiedMessage && (
                            <p className="text-green-400">Referral link copied to clipboard!</p>
                        )}
                    </div>

                    {/* Rewards Section */}
                    <div className="bg-gray-700 p-6 rounded-lg shadow-lg space-y-4">
                        <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                            <FaCoins className="text-yellow-400" /> Referral Rewards
                        </h3>
                        <p className="text-gray-400">
                            Earn{" "}
                            <span className="text-green-400 font-bold">
                                {referredUsersData?.referral_reward_coins || 20} coins
                            </span>{" "}
                            for each successful referral. The more you refer, the more you earn!
                        </p>
                    </div>

                    {/* Referrals Count Section */}
                    <div className="bg-gray-700 p-6 rounded-lg shadow-lg space-y-4">
                        <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                            <FaUserFriends className="text-blue-400" /> Your Referrals
                        </h3>
                        <p className="text-gray-400">
                            You have referred{" "}
                            <span className="text-green-400 font-bold">
                                {referredUsersData?.referredUsers?.length || 0}
                            </span>{" "}
                            friends.
                        </p>
                    </div>

                    {/* Referred Users Section */}
                    <div className="bg-gray-700 p-6 rounded-lg shadow-lg space-y-4">
                        <h3 className="text-lg font-semibold text-white">Referred Users</h3>
                        {referredUsersLoading ? (
                            <p className="text-white">Loading referred users...</p>
                        ) : referredUsersError ? (
                            <p className="text-red-500">Error fetching referred users.</p>
                        ) : referredUsersData?.referredUsers?.length > 0 ? (
                            <div>
                                {/* Table for Desktop */}
                                <div className="overflow-x-auto hidden sm:block">
                                    <table className="min-w-full mt-4 text-white">
                                        <thead>
                                            <tr className="text-left bg-gray-700">
                                                <th className="px-4 py-2">Name</th>
                                                <th className="px-4 py-2">Email</th>
                                                <th className="px-4 py-2">Referred On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {referredUsersData.referredUsers.map((referral, index) => (
                                                <tr
                                                    key={referral.id}
                                                    className={`border-b border-gray-700 ${index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
                                                        }`}
                                                >
                                                    <td className="px-4 py-2">
                                                        {referral.first_name} {referral.last_name}
                                                    </td>
                                                    <td className="px-4 py-2">{referral.email}</td>
                                                    <td className="px-4 py-2">
                                                        {new Date(referral.created_at).toLocaleDateString()}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {/* List for Mobile */}
                                <div className="block sm:hidden">
                                    <ul className="mt-4 space-y-4">
                                        {referredUsersData.referredUsers.map((referral) => (
                                            <li
                                                key={referral.id}
                                                className="flex flex-wrap gap-2 justify-between items-center bg-gray-700 px-4 py-2 rounded-lg border border-gray-700"
                                            >
                                                <div>
                                                    <span className="text-white">
                                                        {referral.first_name} {referral.last_name}{" "}
                                                        <span className="text-gray-400">({referral.email})</span>
                                                    </span>
                                                </div>
                                                <div className="text-green-400 text-sm">
                                                    Referred On: {new Date(referral.created_at).toLocaleDateString()}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <p className="text-gray-400">No referred users yet.</p>
                        )}
                    </div>
                </>
            )}
            {showModal && <Profile closeModel={handleCloseModal} />}
        </div>
    );
};

export default ReferralPage;
