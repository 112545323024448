import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://api.quizyfy.com/api",
  // baseUrl: "http://localhost:5010/api",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => "/categories",
    }),

    getQuizzes: builder.query({
      query: () => "/quizes",
    }),

    getQuizzesByCategory: builder.query({
      query: (categoryId) => `/quizes/${categoryId}`,
    }),

    getQuestionsByQuiz: builder.query({
      query: (quizId) => `/questions/${quizId}`,
    }),

    login: builder.mutation({
      query: (userData) => ({
        url: "/login",
        method: "POST",
        body: userData,
      }),
    }),

    getMe: builder.query({
      query: () => "/me",
    }),

    submitKYC: builder.mutation({
      query: (formData) => ({
        url: "/user-kyc",
        method: "POST",
        body: formData,
      }),
    }),

    withdrawRequest: builder.mutation({
      query: (payload) => ({
        url: `/withdraw-request`,
        method: "POST",
        body: payload,
      }),
    }),

    getKYCDetails: builder.query({
      query: () => "/kyc",
    }),

    uploadImage: builder.mutation({
      query: (fileData) => ({
        url: "/upload-image",
        method: "POST",
        body: fileData,
      }),
    }),

    quizPlayedByUser: builder.mutation({
      query: (data) => ({
        url: "/quiz-played",
        method: "POST",
        body: data,
      }),
    }),

    getWithdrawHistories: builder.query({
      query: () => "/withdraw-histories",
    }),

    convertCoinsToRupees: builder.mutation({
      query: (coins) => ({
        url: "/convert-coins",
        method: "POST",
        body: { coins },
      }),
    }),
    getReferredUsers: builder.query({
      query: (userId) => `/users/${userId}/referred`, // Endpoint to get referred users
    }),
  }),
});

export const {
  useLazyGetCategoriesQuery,
  useLazyGetQuizzesQuery,
  useLazyGetQuizzesByCategoryQuery,
  useLazyGetQuestionsByQuizQuery,
  useLoginMutation,
  useLazyGetMeQuery,
  useSubmitKYCMutation,
  useWithdrawRequestMutation,
  useLazyGetKYCDetailsQuery,
  useUploadImageMutation,
  useQuizPlayedByUserMutation,
  useLazyGetWithdrawHistoriesQuery,
  useConvertCoinsToRupeesMutation,
  useLazyGetReferredUsersQuery,
} = apiSlice;
