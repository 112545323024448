import { useState } from "react";
import { useSelector } from "react-redux";
import { useConvertCoinsToRupeesMutation } from "../api/apiSlice";

const useCoinConversion = () => {
  const [coinAmount, setCoinAmount] = useState("");
  const [rupeeAmount, setRupeeAmount] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isConversionSuccessful, setIsConversionSuccessful] = useState(false);
  const [convertCoinsToRupees, { isLoading }] =
    useConvertCoinsToRupeesMutation();
  const user = useSelector((state) => state.user.data);
  const userCoins = user?.coins || 0;

  const handleConversion = async () => {
    if (!coinAmount || isNaN(coinAmount) || coinAmount <= 0) {
      setMessage("Please enter a valid coin amount.");
      setMessageType("error");
      return;
    }

    if (coinAmount > userCoins) {
      setMessage(
        `You only have ${userCoins} coins. Please enter a valid amount.`
      );
      setMessageType("error");
      return;
    }

    try {
      const response = await convertCoinsToRupees(coinAmount).unwrap();
      setRupeeAmount(response.data.rupees);
      setMessage(`The equivalent rupee amount is ₹${response.data.rupees}`);
      setMessageType("success");
      setIsConversionSuccessful(true);
    } catch (error) {
      setMessage(
        error?.data?.message ||
          "An error occurred during conversion. Please try again."
      );
      setMessageType("error");
      setIsConversionSuccessful(false);
    }
  };

  return {
    coinAmount,
    setCoinAmount,
    rupeeAmount,
    message,
    messageType,
    handleConversion,
    isConversionSuccessful,
    isLoading,
  };
};

export default useCoinConversion;
