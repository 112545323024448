import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  quizzes: [],
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setQuizzes: (state, action) => {
      state.quizzes = action.payload;
    },
  },
});

export const { setCategories, setQuizzes } = dataSlice.actions;

export default dataSlice.reducer;
