import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import QuizPlay from "./pages/QuizPlay";
import Header from "./components/Header";
import ResultPage from "./pages/ResultPage";
import WithdrawalHistoryPage from "./pages/WithdrawalHistoryPage";
import WithdrawalPageNew from "./pages/WithdrawalPageNew";
import { FiArrowUp } from "react-icons/fi"; // Import the "up arrow" icon
import KycPage from "./pages/KycPage";
import ReferralPage from "./pages/ReferralPage";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component
import NotFound from "./pages/NotFound"; // Import the 404 Page

const App = () => {
  const [showGoTop, setShowGoTop] = useState(false);

  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowGoTop(true);
      } else {
        setShowGoTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  return (
    <Router>
      <div
        className="min-h-screen"
        style={{
          backgroundImage: `linear-gradient(to bottom right, #1f2937, #111827), url('/element.png')`, // Main Background
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="h-full bg-opacity-90">
          <Header title={"Quiz App"} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/referral" element={<ReferralPage />} />

            {/* Protected routes */}
            <Route
              path="/withdraw-history"
              element={<ProtectedRoute element={<WithdrawalHistoryPage />} />}
            />
            <Route
              path="/kyc"
              element={<ProtectedRoute element={<KycPage />} />}
            />
            <Route
              path="/withdrawal"
              element={<ProtectedRoute element={<WithdrawalPageNew />} />}
            />
            <Route
              path="/quiz/:quizId"
              element={<ProtectedRoute element={<QuizPlay />} />}
            />
            <Route
              path="/results"
              element={<ProtectedRoute element={<ResultPage />} />}
            />

            {/* Catch-all for 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>

        {/* "Go to Top" Button with teal color scheme */}
        {showGoTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-6 right-6 bg-gradient-to-r from-teal-500 to-teal-700 hover:bg-gradient-to-l text-white md:p-4 p-2 rounded-full shadow-lg transition-all duration-300 flex items-center justify-center"
            aria-label="Go to Top"
          >
            <FiArrowUp className="h-6 w-6 md:h-8 md:w-8" />
          </button>
        )}
      </div>
    </Router>
  );
};

export default App;
