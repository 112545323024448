const LoadingSpinner = () => (
    <div className="flex justify-center items-center space-x-4 py-4">
        <div className="relative w-16 h-16 bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 rounded-full flex items-center justify-center text-3xl font-bold text-white animate-spin-slow">
            <div className="absolute top-0 left-0 w-full h-full border-4 border-dotted border-t-4 border-white rounded-full animate-spin"></div>
            <div className="relative z-10">?</div>
        </div>
    </div>
);


export default LoadingSpinner;
